<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <h6 class="breadcrumb-text">Chat with NeXa</h6>
      </div>
      <div>
        <button @click="toggleSidebar" class="open_sidebar me-2">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3 p-0">
        <div
          class="d-flex justify-content-end cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 0 0 8px">Back</span>
        </div>
      </div>
      <div class="full-height col-md-10 mt-5 p-0">
        <div
          class="mt-auto mb-0"
          style="overflow-y: scroll"
          ref="scrollContainer"
        >
          <b-container
            v-for="(q, index) in questions"
            :key="index"
            class="bv-example-row bv-example-row-flex-cols mb-4"
            fluid
          >
            <b-row v-if="index === 0" class="mb-4">
              <b-col class="col-auto ms-auto p-3 pt-2 chat-box">
                <div>
                  {{ q.question }}
                </div>
                <div class="mt-2">
                  <button
                    @click="toggleSidebar"
                    class="custom-button"
                    v-if="questionDocuments.length > 0"
                  >
                    <img
                      class="me-2 custom-img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/072fca6f880e47769db5f7634dd8a8c6/8bb7d4b6ef15f64c939e8fdd286600c890c3ce905a12de78aa979d8dff483648?apiKey=072fca6f880e47769db5f7634dd8a8c6&amp;"
                    />
                    <span>Uploaded {{ questionDocuments.length }} files</span>
                  </button>
                </div>
              </b-col>
              <b-col class="col-auto p-0">
                <div class="triangle-element"></div>
              </b-col>
            </b-row>

            <b-row v-else class="mb-4">
              <b-col class="col-auto ms-auto p-3 pt-2 chat-box">
                <div>
                  {{ q.question }}
                </div>
              </b-col>
              <b-col class="col-auto p-0">
                <div class="triangle-element"></div>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="col-auto pe-0">
                <figure class="image-container">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/072fca6f880e47769db5f7634dd8a8c6/f182ff80fdd97994dfff80435dce75b133fcee15dde95d20b26d47a72b0e1001?apiKey=072fca6f880e47769db5f7634dd8a8c6&amp;"
                    alt=""
                    class="content-image"
                  />
                </figure>
              </b-col>
              <b-col class="col-auto p-0">
                <div class="triangle-element-nexa"></div>
              </b-col>
              <b-col
                v-if="q.answer === null"
                md="12"
                class="col-auto p-3 pt-1 pb-1 chat-box-nexa"
              >
                <div>
                  <h6 v-if="index === 0" class="extract-text">
                    Extracting files
                  </h6>
                </div>
                <div class="d-flex align-items-center mt-1">
                  <b-spinner
                    class="ms-1 me-2"
                    variant="primary"
                    label="Nexa Thinking"
                    style="
                      width: 1.2rem;
                      height: 1.2rem;
                      border-width: 0.125rem;
                    "
                  ></b-spinner>
                  <h6 class="nexa-thinking">Thinking...</h6>
                </div>
              </b-col>

              <b-col v-else class="col-auto p-3 chat-box-nexa">
                <h6 class="nexa-thinking">
                  This is a generated response from NeXa...
                </h6>
                <div class="mb-3 mt-3">
                  <article ref="answerContent">
                    <vue-marked>{{ q.answer }}</vue-marked>
                  </article>
                </div>
                <!-- <div v-else class="mb-3">
                  <table class="case-summary mt-4" aria-label="Case Summary Details">
                    <thead>
                      <tr>
                        <th v-for="(value, key) in JSON.parse(q.answer).table[0]" :key="key" scope="col">
                          {{ key }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in JSON.parse(q.answer).table" :key="item['Case Citation']">
                        <td v-for="(value, key) in item" :key="key">
                          {{ value }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <vue-marked v-if="JSON.parse(q.answer).string">{{
                  JSON.parse(q.answer).string
                }}</vue-marked> -->

                <!-- <vue-marked v-else-if="JSON.parse(q.answer).table">{{
                  JSON.parse(q.answer).table
                }}</vue-marked> -->

                <div>
                  <button @click="copyAnswer(q, index)">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/072fca6f880e47769db5f7634dd8a8c6/9362a8923d32ca8bc5c04a2b7240493a0b95be121fbf3951ec46c906c790bfd2?apiKey=072fca6f880e47769db5f7634dd8a8c6&amp;"
                    />
                  </button>
                  <button @click="resubmitQuery(q)">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/072fca6f880e47769db5f7634dd8a8c6/eae4d16b1ac11d42038051a950c3e42d5b06c6a5a93ae7facbb44db102a392ba?apiKey=072fca6f880e47769db5f7634dd8a8c6&amp;"
                    />
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="main-container">
          <div class="chat-section">
            <transition name="slide">
              <div class="normal">
                <div>
                  <div>
                    <b-container class="input-container" fluid>
                      <b-row>
                        <b-col
                          sm="11"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <button
                            class="mt-2"
                            :disabled="blockUser"
                            @click="$bvModal.show('showModal')"
                            :style="{
                              cursor: blockUser ? 'not-allowed' : 'pointer',
                            }"
                          >
                            <span
                              class="material-symbols-rounded ms-2"
                              style="color: #0e4485"
                              >attach_file</span
                            >
                          </button>
                          <input
                            id="questionInput"
                            v-model="questionInput"
                            type="text"
                            placeholder="Enter your follow-up question here."
                            class="input-field"
                            :disabled="blockUser"
                            :style="{
                              cursor: blockUser ? 'not-allowed' : 'auto',
                            }"
                            @keydown.enter="submitQuery"
                          />
                        </b-col>
                        <b-col
                          class="d-flex justify-content-end align-items-center"
                        >
                          <button
                            class="me-3 mt-2"
                            :disabled="blockUser"
                            @click="submitQuery"
                            :style="{
                              cursor: blockUser ? 'not-allowed' : 'pointer',
                            }"
                          >
                            <span
                              class="material-symbols-rounded blu"
                              style="color: #86888d"
                              >send</span
                            >
                          </button>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col
                          v-for="(file, index) in displayedFiles"
                          :key="index"
                          sm="4"
                          class="align-items-center mt-2 mb-1"
                        >
                          <div class="d-flex file-background">
                            <div class="d-flex align-items-center ms-1">
                              <span
                                class="material-symbols-rounded badge-icon me-2"
                              >
                                {{
                                  file.name.endsWith(".zip")
                                    ? "folder"
                                    : "description"
                                }}
                              </span>
                              <p class="badge-title" style="margin: 0">
                                {{ truncateFileName(file.name) }}
                              </p>
                            </div>
                            <div class="ms-auto d-flex align-items-center me-1">
                              <h6 class="badge-size mb-0 me-2">
                                {{
                                  (file.file.size / (1024 * 1024)).toFixed(2)
                                }}
                                mb
                              </h6>
                              <span
                                class="material-symbols-rounded close-icon"
                                @click="removeFile(index)"
                                >close</span
                              >
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-container>

                    <b-modal id="showModal" hide-footer hide-header size="">
                      <div class="d-flex justify-content-between">
                        <div>
                          <h5>Upload Files</h5>
                        </div>
                        <div>
                          <span
                            class="material-symbols-rounded close-icon"
                            @click="$bvModal.hide('showModal')"
                            >close</span
                          >
                        </div>
                      </div>
                      <div>
                        <FileInput
                          style="flex-grow: 1"
                          :accept="['pdf', 'docx', 'png', 'jpg', 'jpeg']"
                          @input-files="$set(files, 0, $event)"
                        />
                        <div class="mt-2 d-flex">
                          <span class="material-symbols-rounded mt-1 help-icon"
                            >help</span
                          >
                          <p class="help-description">
                            If you have multiple files to upload, we recommend
                            zipping them into one file to make the process
                            quicker and easier.
                          </p>
                        </div>
                        <FileProcess
                          style="
                            width: 100%;
                            max-height: 200px;
                            overflow-y: auto;
                          "
                          :accept="['pdf', 'docx', 'png', 'jpg', 'jpeg']"
                          :files="files[0]"
                          @output-files="$set(files, 1, $event)"
                        />
                      </div>
                      <footer>
                        <div class="d-flex justify-content-end">
                          <div
                            class="upload cursor-pointer"
                            @click="uploadFile"
                          >
                            <span class="material-symbols-rounded icon"
                              >upload</span
                            >
                            Upload
                          </div>
                        </div>
                      </footer>
                    </b-modal>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <sidebar
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="questionDocuments"
    ></sidebar>
  </div>
</template>

<script>
import VueMarked from "vue-marked";
// import marked from "marked";
import fileImg from "../../assets/copilot/file.png";
import chat from "../../assets/copilot/chat.png";
import star from "../../assets/copilot/Vector.svg";
import FileInput from "../../components/input/FileInput.vue";
import FileProcess from "../../components/input/FileProcess.vue";
import nexa from "@/store/nexa.js";
import sidebar from "./sidebar.vue";

export default {
  components: {
    FileProcess,
    FileInput,
    sidebar,
    VueMarked,
  },
  data() {
    return {
      sidebarCollapsed: false,
      isSidebarVisible: false,
      fileImg,
      chat,
      star,
      expertmode: false,
      showModal: false,
      files: [[], [], []],
      trial_file: [],
      NewText: "",
      loadAnswer: false,
      chatMessages: [],
      showQuestions: false,
      questionDocuments: [],
      isFetching: false,
      questions: "",
      answer: "",
      questionInput: "",
      blockUser: false,
    };
  },
  created() {
    nexa.GetResult(this.$route.params.id ?? "").then((response) => {
      const DATA = response.data.data;
      if (DATA.completedPerCentage >= 100) {
        this.loading = false;
      }

      // this.question = DATA.questions[0].question;
      this.questionDocuments = DATA.questionDocuments;
      this.documentPercent = DATA.questionDocuments.map((q) =>
        JSON.parse(q.completePercentage)
      );
      this.documentName = DATA.questionDocuments.map((q) => q.name);

      this.answers = DATA.questions;

      this.questions = DATA.questions || [];
      const hasNullValue = this.questions.some((q) => q.answer === null);

      if (hasNullValue) {
        this.refetchAnswers();
      }

      this.collapsedQuestions = this.questions.map((_, index) => index);

      this.$nextTick(() => {
        this.scrollToBottom();
      });
    });
  },
  computed: {
    displayedFiles() {
      return this.showAll
        ? this.files[2]
        : this.files[2].slice(0, this.maxFilesToShow);
    },
  },
  methods: {
    async refetchAnswers() {
      if (this.isFetching) return;
      this.isFetching = true;

      const checkAllAnswers = () =>
        this.questions.every((q) => q.answer !== null);

      const checkAllFilesComplete = () =>
        this.documentPercent.every((percent) => percent >= 100);

      while (!checkAllAnswers() || !checkAllFilesComplete()) {
        const updatedQuestions = await Promise.all(
          this.questions.map(async (element) => {
            if (element.answer == null) {
              try {
                const response = await nexa.GetResult(
                  this.$route.params.id ?? ""
                );

                const updatedQuestion = response.data.data.questions.find(
                  (q) => q.id === element.id
                );

                if (updatedQuestion && updatedQuestion.answer !== null) {
                  return {
                    ...element,
                    answer: updatedQuestion.answer,
                  };
                }
                return element;
              } catch (error) {
                console.error("Error fetching result:", error);
                return element;
              }
            }
            return element;
          })
        );

        this.questions = updatedQuestions;

        try {
          const response = await nexa.GetResult(this.$route.params.id ?? "");
          this.questionDocuments = response.data.data.questionDocuments;
        } catch (error) {
          console.error("Error fetching file status:", error);
        }

        this.blockUser = true;

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      this.blockUser = false;
      this.isFetching = false;
    },

    async refetchFiles() {
      if (this.isFetching) return;
      this.isFetching = true;

      const checkAllFilesComplete = () =>
        this.documentPercent.every((percent) => percent >= 100);

      while (checkAllFilesComplete()) {
        try {
          const response = await nexa.GetResult(this.$route.params.id ?? "");
          this.documentPercent = response.data.data.questionDocuments.map((q) =>
            JSON.parse(q.completePercentage)
          );
          this.documentName = response.data.data.questionDocuments.map(
            (q) => q.name
          );
        } catch (error) {
          console.error("Error fetching file status:", error);
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      this.isFetching = false;
    },

    submitQuery() {
      this.blockUser = true;

      const formData = new FormData();
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", this.questionInput);

      if (this.files[2] && this.files[2].length > 0) {
        this.files[2].forEach((file, index) => {
          formData.append(`question_doc[${index}]`, file.file);
        });
      }
      this.questionInput = "";
      this.files = [[], [], []];

      nexa
        .DetailAsk(this.$route.params.id, formData)
        .then((response) => {
          const responseData = response.data?.data;
          const responseQuestions = responseData?.questions;
          this.refetchAnswers();

          if (responseQuestions && Array.isArray(responseQuestions)) {
            responseQuestions.forEach((q) => {
              const existing = this.questions.find(
                (existing) => existing.id === q.id
              );
              if (existing) {
                existing.answer = q.answer;
              } else {
                this.questions.push(q);
              }
            });

            this.refetchAnswers();
            // this.refetchFiles();
            this.$nextTick(() => {
              this.scrollToBottom();
            });

            if (response.data.completedPercentage === 100) {
              this.blockUser = true;
            }
          } else {
            console.error(
              "Error: responseQuestions is undefined or not an array",
              response
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
        });
    },

    resubmitQuery(q) {
      this.blockUser = true;

      const formData = new FormData();
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", q.question);

      if (this.files[2] && this.files[2].length > 0) {
        this.files[2].forEach((file, index) => {
          formData.append(`question_doc[${index}]`, file.file);
        });
      }

      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
      this.questionInput = "";
      this.files = [[], [], []];

      nexa
        .DetailAsk(this.$route.params.id, formData)
        .then((response) => {
          const responseData = response.data?.data;
          const responseQuestions = responseData?.questions;
          this.refetchAnswers();

          if (responseQuestions && Array.isArray(responseQuestions)) {
            responseQuestions.forEach((q) => {
              const existing = this.questions.find(
                (existing) => existing.id === q.id
              );
              if (existing) {
                existing.answer = q.answer;
              } else {
                this.questions.push(q);
              }
            });

            this.refetchAnswers();
            this.$nextTick(() => {
              this.scrollToBottom();
            });

            if (response.data.completedPercentage === 100) {
              this.blockUser = true;
            }
          } else {
            console.error(
              "Error: responseQuestions is undefined or not an array",
              response
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
        });
    },

    scrollToBottom() {
      const container = this.$refs.scrollContainer;
      container.scrollTop = container.scrollHeight;
    },

    copyAnswer(q, index) {
      let content;

      try {
        const parsedAnswer = JSON.parse(q.answer);
        if (parsedAnswer.string) {
          content = parsedAnswer.string
            .replace(/(\*\*)|(\*)/g, "")
            .replace(/---+/g, "\n---\n")
            .replace(/###/g, "\n## ")
            .replace(/##/g, "\n# ")
            .replace(/# /g, "\n# ")
            .replace(/(\r\n|\n|\r)/g, "\n");

          this.$toast.success("Content copied to clipboard");
        } else if (parsedAnswer.table) {
          const parsedAnswer = JSON.parse(q.answer);
          const table = parsedAnswer.table
            .map((item) => {
              const result = [];

              for (const [key, value] of Object.entries(item)) {
                result.push(`${key}:\t${value || "[None]"}`);
              }

              return result.join("\n");
            })
            .filter(Boolean)
            .join("\n\n");

          content = table;

          this.$toast.success("Table copied to clipboard");
        }
      } catch (error) {
        console.error("Unable to parse the answer:", error);
        return;
      }

      navigator.clipboard
        .writeText(content)
        .then(() => {
          if (!this.isCopied[index]) {
            this.$set(this.isCopied, index, true);
          }
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        })
        .finally(() => {
          setTimeout(() => {
            this.$set(this.isCopied, index, false);
          }, 2000);
        });
    },

    truncateFileName(name) {
      return name.length > 25 ? name.substring(0, 29) + "..." : name;
    },

    removeFile(index) {
      this.files[2].splice(index, 1);
    },

    uploadFile() {
      this.$set(this.files, 2, this.files[1]);
      this.$set(this.files, 0, []);
      this.$set(this.files, 1, []);
      this.$bvModal.hide("showModal");
    },
    formatDateTime(date) {
      return new Date(date).toISOString();
    },

    getTimeDifference(date) {
      const now = new Date();
      const createdAt = new Date(date);
      const diffInMinutes = Math.floor((now - createdAt) / (1000 * 60));

      if (diffInMinutes < 60) {
        return `${diffInMinutes} m`;
      } else {
        const diffInHours = Math.floor(diffInMinutes / 60);
        return `${diffInHours} h`;
      }
    },
    collapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    goBack() {
      this.$router.push({ name: "ExpertMode" });
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    handleFileOutput(event) {
      this.files = event;
    },
    NexaResult(id) {
      this.$router.push({ name: "ChatNeXa", params: { id } });
    },
    fetchQuestions() {
      nexa
        .GetResult(this.$route.params.id ?? "")
        .then((response) => {
          const questionsData = response.data?.data?.questions;
          this.questionDocuments = response.data?.data?.questionDocuments;
          if (Array.isArray(questionsData)) {
            const completedPerCentage =
              response.data?.data?.completedPerCentage;

            questionsData.forEach((question) => {
              this.chatMessages.push({
                type: "question",
                content: question.question,
                id: question.id,
                files: [],
              });

              if (question.answer) {
                this.chatMessages.push({
                  type: "answer",
                  content: question.answer,
                  id: question.id,
                });
              } else if (completedPerCentage && completedPerCentage < 100) {
                const thinkingMessage = {
                  type: "answer",
                  content: "Thinking...",
                  id: `thinking-${question.id}`,
                };

                this.chatMessages.push(thinkingMessage);
              }
            });
          } else {
            console.error(
              "Error: questionsData is not an array:",
              questionsData
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching questions:", error);
        });
    },

    askQuestion() {
      if (this.NewText.trim()) {
        const newQuestion = {
          type: "question",
          content: this.NewText,
          files: [],
        };

        this.chatMessages.push(newQuestion);
        this.loadAnswer = true;
        this.completedPerCentage = 0;
        const thinkingMessage = {
          type: "answer",
          content: "Thinking...",
          id: new Date().getTime(),
        };

        this.chatMessages.push(thinkingMessage);
        const intervalId = setInterval(() => {
          if (this.completedPerCentage < 100) {
            this.completedPerCentage += 10;
          }
        }, 500);

        setTimeout(() => {
          const answer = {
            type: "answer",
            content: "This is a generated response from NeXa.",
          };

          const thinkingIndex = this.chatMessages.findIndex(
            (msg) => msg.content === "Thinking..."
          );
          if (thinkingIndex !== -1) {
            this.chatMessages[thinkingIndex] = answer;
          } else {
            this.chatMessages.push(answer);
          }

          clearInterval(intervalId);
          this.loadAnswer = false;
          this.completedPerCentage = 100;

          this.NewText = "";
        }, 2000);
      }
    },
  },
};
</script>

<style scoped>
.full-height {
  /* background: red; */
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.chat-box {
  border-radius: 6px 0px 6px 6px;
  background: var(--Status-Blue, #1890ff);
  max-width: 70%;
  color: #fff;
}

.custom-button {
  color: #fff;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
}

.custom-button:hover {
  background-color: #fff;
  color: #1890ff;
}

.custom-button:hover .custom-img {
  filter: brightness(0) saturate(100%) invert(30%) sepia(100%) saturate(500%)
    hue-rotate(170deg);
}

.triangle-element {
  width: 15px;
  height: 15px;
  overflow: hidden;
  position: relative;
}
.triangle-element:after {
  content: "";
  background: var(--Status-Blue, #1890ff);
  width: 142%;
  height: 142%;
  position: absolute;
  transform: rotate(45deg) translate(-80%, 0);
  border-radius: 0px 8px 0px;
}

.chat-box-nexa {
  border-radius: 0px 6px 6px 6px;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  max-width: 85%;
}

.case-summary {
  font-size: 0.85em; /* Reduce font size */
  width: 100%;
  border-collapse: collapse; /* Reduce spacing between cells */
}
.case-summary th,
.case-summary td {
  padding: 4px 8px; /* Reduce cell padding */
  border: 1px solid #ddd; /* Add a light border */
  text-align: left;
}
.case-summary th {
  background-color: #f2f2f2; /* Add a background color to headers */
  font-weight: bold;
}

.extract-text {
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.nexa-thinking {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.image-container {
  border-radius: 10000px;
  background: var(--Primary-Blue, #0e4485);
  padding: 3px;
}

.content-image {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.triangle-element-nexa {
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: relative;
}
.triangle-element-nexa:after {
  content: "";
  background: var(--Neutral-Light-Grey, #f2f3f3);
  width: 142%;
  height: 142%;
  position: absolute;
  /* transform: rotate(45deg) translate(-71%, 0); */
  transform: rotate(128deg) translate(-67%, 0);
  border-radius: 0px 0px 2px;
}

.main-container {
  margin-bottom: 0%;
}

.chat-section {
  padding: 0px;
}
.input-container {
  width: 100%;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid var(--Primary-Blue, #0e4485);
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 2px 10px 0px rgba(14, 68, 133, 0.6);
}

.input-field {
  flex: 1; /* Allow the input field to grow */
  border: none;
  padding: 8px;
  font-size: 16px;
  background: none;
  outline: none;
  overflow: hidden; /* Prevent overflow of text */
  white-space: nowrap; /* Keep text in a single line */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  min-width: 0; /* Allow shrinking */
}

.file-background {
  background: #fafbfc;
  border-radius: 8px;
}

.badge-icon {
  color: var(--primary);
}

.badge-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.badge-size {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.close-icon {
  font-size: 18px;
  color: var(--status-red);
  cursor: pointer;
}

.page-content {
  padding: 0;
  overflow: hidden;
}

.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 16px 20px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
}
.breadcrumb-text {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  margin: 0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}

.input-field {
  flex: 1;
  border: none;
  padding: 0 10px;
  font-size: 16px;
}

.nr_send {
  color: var(--primary);
}

.prompt_css {
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: var(--neutral-black);
}
</style>
<style scoped>
.chat-bubble {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.right {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}

.right button {
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.left {
  align-self: flex-start;
  display: flex;
  align-items: center;
}

.right {
  width: 50%;
  float: right;
  background: var(--Status-Blue, #1890ff);
  border-radius: 6px;
  display: flex;
  padding: 8px;
  align-items: center;
  color: #fff !important;
}
.left {
  display: flex;
  align-items: center;
}

.left span.smart_toy {
  background-color: var(--primary);
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}

.left .left-bubble {
  width: 600px;
  padding: 10px;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 0px 6px 6px 0px;
}

.left div p {
  margin: 0;
  font-size: 14px;
}

.rotate-icon {
  display: inline-block;
  animation: rotate 1s linear infinite;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: #1890ff !important;
  font-size: 23px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<style scoped>
.main-container {
  width: 100%;
  position: relative;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 10%;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
}

.help-icon {
  font-size: 18px;
  color: #86888d;
}

.help-description {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  padding-left: 5px;
  color: #86888d !important;
}

.breadcrumb {
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.container {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 70px 14px;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1076px;
}

@media (max-width: 991px) {
  .content {
    max-width: 100%;
    padding-right: 20px;
  }
}

.header {
  text-align: center;
  max-width: 100%;
}
.title {
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.subtitle {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.search-bar {
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
  width: 100%;
}

.input-group {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}
.input-field {
  flex: 1;
  color: var(--Neutral-Mid-Grey, #000);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  padding: 12px;
  border: none;
  outline: none;
}

.upload-button {
  border-radius: 4px;
  overflow: hidden;
  padding: 4px;
  padding-right: 10px;
  right: 0;
  position: absolute;
}

.upload-button img {
  width: 20px;
  object-fit: contain;
}

.tooltip {
  align-self: start;
  border-radius: 4px;
  color: var(--Neutral-White, #fff);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 6px 8px;
  margin-top: -14px;
}

.tooltip-arrow {
  position: absolute;
  left: 16px;
  bottom: 8px;
  width: 11px;
  height: 6px;
  background: var(--Neutral-Black, #383a3e);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.search-bar-footer {
  background: #fafbfc;
  border-top: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  display: flex;
  justify-content: space-between;
  padding: 6px 20px;
  margin-top: -16px;
}
.attach-button,
.country-button {
  color: #86888d;
  font-size: 14px;
  font-weight: 500;
}
.char-count {
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.prompts {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.prompt-button {
  background: var(--Neutral-Light-Grey, #f2f3f3);
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 16px !important;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 12px;
}
.load-more-button {
  background: none;
  color: var(--Primary-Blue, #0e4485);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 8px;
}

.use-cases {
  margin-top: 35px;
}
.use-cases-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.use-cases-title {
  max-width: 218px;
}
.use-cases-controls {
  display: flex;
  gap: 20px;
}
.filter-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 11px 10px;
  border-radius: 4px;
}
.search-container {
  padding: 8px 8px;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 4px;
  background: var(--Neutral-White, #fff);
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 4px;
}
.search-container {
  padding: 8px 8px;
}
.search-field {
  border: none;
  outline: none;
}

.use-case-grid {
  margin-top: 11px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.use-case-card {
  height: 212px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(227, 229, 235, 0.6);
  padding: 24px;
  /* flex: 1 1 240px; */
  flex: 1 1 300px;
}
.use-case-card:hover {
  outline: 1px solid var(--primary);
}
.use-case-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 20px;
  font-weight: 600;
}
.use-case-description {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}
.create-button {
  display: none;
  /* width: 240px; */
  width: 300px;
  align-self: end;
  background: var(--Primary-Blue, #0e4485);
  border-radius: 4px;
  color: var(--Neutral-White, #fff);
  font-size: 16px;
  font-weight: 500;
  padding: 4px 16px;
  margin-top: 16px;
}
.use-case-card:hover .create-button {
  display: block;
}
.decorative-image {
  width: 20px;
  object-fit: contain;
  margin-top: 204px;
}

@media (max-width: 991px) {
  .decorative-image {
    margin-top: 40px;
  }
}
</style>
<style scoped>
.sidebar {
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px rgba(170, 171, 175, 0.6);
  display: flex;
  max-width: 300px;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  padding: 24px 12px;
}

.sidebar-toggle,
.new-chat-button,
.show-all-button {
  border-radius: 4px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  justify-content: flex-start;
  padding: 4px 16px;
  background: none;
  border: none;
}

.icon,
.search-icon {
  width: 24px;
  height: 24px;
}
.icon-small {
  color: #86888d;
  margin: auto;
  font-size: 18px;
}
.icon-filter {
  margin: auto;
  font-size: 18px;
}

.sidebar-header {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.new-chat-button {
  background-color: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff);
}

.search-form {
  margin-top: 11px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
  padding: 6px 12px;
  width: 100%;
}
.search-input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.search-icon {
  width: 24px;
  height: 24px;
}
.item-list {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.separator {
  border: 1px solid #f2f3f3;
  width: 100%;
}

.recent-logs-title {
  margin-top: 8px;
  font-size: 16px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.conversation-card,
.alt-card {
  border-radius: 8px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}
.conversation-card:hover {
  background: #f2f3f3;
}

.alt-card {
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
}
.conversation-title {
  font-size: 14px;
  color: var(--Neutral-Black, #383a3e);
  margin: 0;
}

.conversation-details {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: var(--Neutral-Dark-Grey, #86888d);
}

.file-upload-info {
  flex: 1;
}

.sidebar-image {
  position: absolute;
  right: 77px;
  bottom: 97px;
  width: 20px;
  height: 21px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.upload-button {
  span {
    color: #86888d;
  }
}
input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #d1d2d5;
}
.use-case-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

/* Styling the header text */
.use-case-header h2 {
  font-size: 1.5rem; /* Adjust the size */
  font-weight: 600; /* Semi-bold for emphasis */
  color: #333; /* Darker color for readability */
  margin: 0;
}

.use-case-header p {
  color: #888888; /* Light gray for the subtext */
  font-size: 0.875rem; /* Smaller font size for description */
  margin: 0;
  margin-top: 4px; /* Adds slight spacing below the heading */
}

/* Styling the filter and search area */
.filter-search {
  display: flex;
  gap: 10px;
  align-items: center;
}

.filter-button {
  font-size: 0.875rem;
  color: #333; /* Dark text */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #f0f0f0;
}

.search-input::placeholder {
  color: #888888;
}

.icon-search {
  color: #888888;
}
.country-option {
  left: 18%;
  width: 100px !important;
  background: #fafbfc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  position: absolute;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.country-flag {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.countryOption {
  cursor: pointer;
  margin-bottom: 10px;
  span {
    color: #86888d;
    font-size: 14px;
  }
}
.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.close-icon {
  font-size: 18px;
  color: var(--status-red);
  cursor: pointer;
}
.modal-icon {
  width: 24px;
  height: 24px;
  color: #383a3e;
  cursor: pointer;
}
.upload {
  background: var(--primary);
  border-radius: 6px;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
}
.swift {
  border-radius: 4px;
  background: rgba(24, 144, 255, 0.1);
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.toggle {
  display: flex;
  width: 40px;
}
.switch {
  flex-grow: 1;
  background: var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 100px;
  padding: 2px;
  display: flex;
  align-items: center;
}
.button {
  width: 16px;
  height: 16px;
  background: var(--Neutral-White, #fff);
  border-radius: 100px;
  box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
}
.file-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.footer-text {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.overflow-recent {
  max-height: 30vh;
  overflow: auto;
}
</style>
